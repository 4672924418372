import {AsyncPipe, NgIf} from '@angular/common';
import {Injector, NgModule, Optional, SkipSelf} from '@angular/core';
import {LoaderServiceInjector, setLoaderServiceInjector} from '@modules/custom-loader/models/loader-service.injector';

import {BlockLoaderComponent} from './components/block-loader/block-loader.component';
import {LoaderComponent} from './components/loader/loader.component';
import {LoaderDirective} from './directives/loader/loader.directive';

@NgModule({
  declarations: [LoaderDirective, BlockLoaderComponent],
  imports: [
    NgIf,
    AsyncPipe,
    LoaderComponent,
  ],
  exports: [LoaderComponent, LoaderDirective, BlockLoaderComponent],
})
export class CustomLoaderModule {

  constructor(
    private readonly injector: Injector,
    @Optional() @SkipSelf() private parentModule: CustomLoaderModule,
  ) {
    this.setLoaderInjector();
  }

  private setLoaderInjector(): void {
    if (!this.parentModule && !LoaderServiceInjector) {
      setLoaderServiceInjector(this.injector);
    }
  }
}
