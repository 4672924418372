import {AsyncPipe, NgIf} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {LoaderService} from '../../service/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    AsyncPipe,
  ],
})
export class LoaderComponent {

  loading$: Observable<boolean> = this.loaderService.loading$.pipe(
    debounceTime(0),
  );

  constructor(private readonly loaderService: LoaderService) { }

}
